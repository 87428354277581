import {customElement} from 'lit/decorators.js';
import {ComponentAnalytics} from './component-analytics';
import {loadTrackingLibrary} from '../helpers/TrackingLibraryLoaderHelper';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-snapchat-analytics')
class ComponentSnapchatAnalytics extends ComponentAnalytics {

    private snaptr: any;

    @property({type: String})
    pixelId: string;

    protected async startup() {
        let snaptr: any = this.snaptr = function () {
            snaptr.handleRequest ? snaptr.handleRequest.apply(snaptr, arguments) : snaptr.queue.push(arguments);
        };
        snaptr.queue = [];
        (window as any).snaptr = snaptr;


        await loadTrackingLibrary('//sc-static.net/scevent.min.js');
        this.snaptr = (window as any).snaptr;
        this.snaptr('init', this.pixelId);
    }

    protected setUserId(_id: string, _options: any = {}) {
    }

    protected setCurrentScreen(_id: string, _options: any = {}) {
    }

    protected setUserProperties(_properties: object, _options: any = {}) {
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let track = (...args: any[]) => {
            this.snaptr('track', ...args);
        };

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent(params) {
                track('VIEW_CONTENT', {
                    'price': params.value,
                    'currency': params.currency,
                    'item_ids': [params.id],
                    'item_category': params.categories ? params.categories[0] : undefined,
                });
            },
            shopAddToCart(params) {
                track('ADD_CART', {
                    'price': params.value,
                    'currency': params.currency,
                    'item_ids': [params.id],
                    'item_category': params.categories ? params.categories[0] : undefined,
                    'number_items': params.quantity,
                });
            },
            pageView() {
                track('PAGE_VIEW');
            },
            accountRegister(e) {
                track('SIGN_UP', {
                    'sign_up_method': e.method,
                });
            },
            accountLogin() {
                track('LOGIN');
            },
            shopPurchase(params) {
                track('PURCHASE', {
                    'price': params.value,
                    'currency': params.currency,
                    'transaction_id': params.orderId,
                    'item_ids': params.items.map(_ => _.id),
                    'item_category': params.items.map(_ => _.categories).filter(_ => _)[0],
                    'number_items': params.items.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0),
                });
            },
            shopStartCheckout(params) {
                track('START_CHECKOUT', {
                    'price': params.value,
                    'currency': params.currency,
                    'item_ids': params.items.map(_ => _.id),
                    'item_category': params.items.map(_ => _.categories).filter(_ => _)[0],
                    'number_items': params.items.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0),
                });
            },
            interactableView() {
            },
            interactableClick() {
            },
            pwaPrompt() {
            },
            toastShown() {
            },
        };
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-snapchat-analytics': ComponentSnapchatAnalytics;
    }
}