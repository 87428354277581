import {customElement} from 'lit/decorators.js';
import {ComponentAnalytics} from './component-analytics';
import {loadTrackingLibrary} from '../helpers/TrackingLibraryLoaderHelper';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-tiktok-analytics')
class ComponentTiktokAnalytics extends ComponentAnalytics {

    private ttq: any;

    @property({type: String})
    pixelId: string;

    protected async startup() {
        let pixelId = this.pixelId;
        let url = '//analytics.tiktok.com/i18n/pixel/events.js';
        let win = window as any;
        let ttq: any = win.ttq = this.ttq = [];
        ttq._i = {[pixelId]: []};
        ttq._i[pixelId]._u = url;
        ttq._t = {[pixelId]: Date.now()};
        ttq._o = {[pixelId]: {}};
        win.TiktokAnalyticsObject = 'ttq';

        ttq.push(['load', pixelId]);


        await loadTrackingLibrary(`${url}?sdkid=${this.pixelId}&lib=ttq`);
    }

    protected setUserId(_id: string, _options: any = {}) {
    }

    protected setCurrentScreen(_id: string, _options: any = {}) {
    }

    protected setUserProperties(_properties: object, _options: any = {}) {
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let track = (...args: any[]) => {
            this.ttq.track ? this.ttq.track(...args) : this.ttq.push(['track', ...args]);
        };

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent() {
            },
            shopAddToCart() {
            },
            pageView(params) {
                track('PageView', params);
            },
            accountRegister() {
            },
            accountLogin() {
            },
            shopPurchase() {
            },
            shopStartCheckout() {
            },
            interactableView() {
            },
            interactableClick() {
            },
            pwaPrompt() {
            },
            toastShown() {
            },
        };
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-tiktok-analytics': ComponentTiktokAnalytics;
    }
}
