import {FirebaseAnalyticsPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-firebase-analytics';
import './components/component-firebase-remote-config';
import './components/component-facebook-analytics';
import './components/component-trustpilot-analytics';
import './components/component-tiktok-analytics';
import './components/component-snapchat-analytics';
import './components/component-tracking';
import './components/component-tracking-interactable';
import './components/component-firebase-analytics-activity-stream';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';


export class FirebaseAnalyticsLocalPackage extends FirebaseAnalyticsPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            if (localStorage['testMode']) return;

            e.registerPostComponent({
                component: 'component-facebook-analytics',
                properties: {
                    pixelId: '2174968035946622',
                },
            }, undefined, true);
            e.registerPostComponent({
                component: 'component-tiktok-analytics',
                properties: {
                    pixelId: 'CT48VH3C77U1LMCPOPDG',
                },
            }, undefined, true);
            e.registerPostComponent({
                component: 'component-snapchat-analytics',
                properties: {
                    pixelId: '13c201a2-c6d7-4475-83ec-750796a7796c',
                },
            }, undefined, true);
            e.registerPostComponent({
                component: 'component-firebase-analytics',
            }, undefined, true);
            e.registerPostComponent({
                component: 'component-trustpilot-analytics',
                properties: {
                    integrationKey: 'pkHFXXA1DsInx8LT',
                },
            }, undefined, true);

            e.registerPostComponent({
                component: 'component-tracking',
            }, undefined, true);
        },
    };
}
